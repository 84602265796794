.refresh-button-main-div{
    width:40% !important ;
}
.refresh-button{
    color:white;
    border:1px solid #ffff;
    height:fit-content;
    padding: 7px 15px 7px 15px;
    cursor:pointer;
}
