.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.btn-show-card input {
  border: none;
  border-radius: 20px;
}
.btn-show-card button {
  border: none;
  border-radius: 20px;
}
.card-btn {
  background-color: #85744e;
    border-radius: 15px;
  height: 85px;
  width: 140px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  
  }
  .card-btn img{
    height: 40px;
  }
  @media only screen and (max-width: 600px)  {
.card-btn {
background-color: #85744e;
  border-radius: 15px;
height: 75px;
width: 100px;
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

}
.card-btn img{
  height: 35px;
}
  }

.starten-text{
  font-size: 20px;
}
.show_card_main_div{
  flex-direction: column;
  display: flex;
  align-items: center;
}