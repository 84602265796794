.nfc_uid_field {
  /* background: rgb(214, 189, 153);
        color: rgb(214, 189, 153); */
  background: #fff;
  color: #fff;
  border: 1px solid #fff !important;
  pointer-events: none;
}

.nfc_uid_field:focus {
  border: 1px solid #fff !important;
  box-shadow: none;
  outline: none;
}
.scanner-text{
  color: #ae9e85;
  font-size: 24px;
  font-weight: 600;
}