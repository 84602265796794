.thankuText {
    color: #17b5c2;
    padding-top: 10px;
    font-size: 30px;
    font-weight: 600;
    margin-top: -15px;
  }
  .forOrderText {
    /* font-family: amithem; */
    font-size: 30px;
    font-weight: 500;
    /* padding-bottom: 20px; */
  
    margin-top: -17px;
  }
  .forOrderText p {
    margin-top: -17px;
  }
  .orderModalText {
    font-size: 34px;
    font-weight: 600;
    color: rgb(85, 85, 85);
    text-align: center;
    margin-bottom: 30px;
  }
  .divideCalc3 {
    width: 100%;
    padding: 0px 15px;
    background: #ffffff;
    z-index: 1000;
    transition: 0.3s;
    padding-top: 0px;
  }
